import React, { useContext, useEffect } from 'react';
import ScrollableAnchor, { goToAnchor } from 'react-scrollable-anchor';
import { Grid, Row, Col } from 'react-flexbox-grid';

import SourceEmitter from 'libs/emitter';
import { appContext } from 'providers/appProvider';
import { Layout, Seo } from 'components';

import ContentBlock from 'components/Content/ContentBlock';
import ColorBlock from 'components/Content/ColorBlock';

import DownloadCopayCard from '../../codes/components/callout-contents/nspat/DownloadCopayCard';
import DownloadPatientBrochure from '../../codes/components/callout-contents/nspat/DownloadPatientBrochure';

import './resources.scss';

import resourceHeroMobile from '../../assets/images/patient-resources-hero-mobile.png';

const ResourcesPage = () => {
  let Subscription = null;
  const { appConfigs } = useContext(appContext);

  const indication = appConfigs?.siteOptions?.INDICATIONS?.patient;
  // const metaData = appConfigs?.metaData?.patient;

  /*  const brandNames = {
    NASCOBAL: appConfigs?.metaData?.brandNames?.nascobal,
    BARIACTIV: appConfigs?.metaData?.brandNames?.bariactiv,
    NUTRITION: appConfigs?.metaData?.brandNames?.nutrition,
  };
 */
  const triggerHashChange = () => {
    const currentHash = window?.location?.hash?.substring(
      1,
      window?.location?.hash?.length,
    );
    SourceEmitter.emit('HashChangedEventTrigger', currentHash);
  };

  useEffect(() => {
    window.addEventListener('hashchange', triggerHashChange, false);
    // Triggered from Logo Component
    Subscription = SourceEmitter.addListener('gotoTopTriggerEvent', data => {
      goToAnchor('top');
    });
    return () => {
      window.removeEventListener('hashchange', triggerHashChange, false);
      Subscription && Subscription.remove();
    };
  }, []);

  const pageTitle = 'NASCOBAL® | Patient Resources';
  const pageDescription = 'Download helpful resources for NASCOBAL®';

  const renderPage = () => {
    return (
      <div id="nascobal-patient-resource">
        <ContentBlock id="patient-resources-block">
          <Grid
            fluid
            style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
          >
            <Row>
              <Col xs={12}>
                <div id="hero" className="hide-in-small">
                  <h2 className="uppercase">
                    Patient
                    <br className="hide-in-mobile" />
                    Resources
                  </h2>
                </div>
                <div id="hero-mobile" className="show-in-small">
                  <img src={resourceHeroMobile} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <DownloadCopayCard />
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <DownloadPatientBrochure />
              </Col>
            </Row>
          </Grid>
        </ContentBlock>
        <ScrollableAnchor id="isi_anchor">
          <div />
        </ScrollableAnchor>
      </div>
    );
  };

  return (
    <Layout indication={indication} className="patient-resources">
      <Seo pageTitle={pageTitle} pageDescription={pageDescription} />
      <div className="body-content-container">
        <div className="body-content gutter-all">{renderPage()}</div>
      </div>
    </Layout>
  );
};

export default ResourcesPage;
